<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_right" style="color: #000; font-size: 16px">单位：元</span>
      </div>
      <div class="project_individual" v-for="item in quantityList" :key="item.name">
        <div class="project_individual_title">
          {{ item.name }}
        </div>
        <div class="project_individual_center">{{ item.value | applyAmount }}</div>
      </div></el-card
    >
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">年度经营数据</span>
        <span class="title_right"
          ><SearchUserDate
            :title="'统计季度：'"
            :width="'100'"
            :type="'yearStatistics'"
            :introductionType="'quarter'"
            :clearable="true"
            :filterable="true"
            :list="quarterGenerate"
            :yearValue="sectionInfo.yearStatisticsYear"
            v-model="sectionInfo.quarter"
            @change="onYearStatisticsChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'yearStatistics'"
            :introductionType="'quarter'"
            :width="'100'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.quarter"
            v-model="sectionInfo.yearStatisticsYear"
            @change="onYearStatisticsChange"
        /></span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        > -->
      </div>
      <el-table
        :summary-method="getSummaries"
        show-summary
        max-height="550"
        border
        :data="yearStatisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="company"
          min-width="100"
          label="公司"
          fixed="left"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.company | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column prop="projectCount" min-width="110" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>立项数</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>已立项的商务数</div>
              </div>
            </el-tooltip></template
          ></el-table-column
        >
        <el-table-column prop="salesRevenue" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>销售金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额（销售合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.salesRevenue | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoicedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoicedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="procurement" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>采购金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额带出（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.procurement | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="receivedInvoices" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>收票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedInvoices | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="payments" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>付款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.payments | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="businessExpend"
          sortable
          min-width="130"
          label="商务报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.businessExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectExpend"
          sortable
          min-width="130"
          label="项目报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.projectExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="publicSpendingExpend"
          sortable
          min-width="130"
          label="公共报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.publicSpendingExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="reimbursements"
          sortable
          min-width="130"
          label="总报销"
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>总报销</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务报销 + 项目报销 + 公共报销</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.reimbursements | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualReceipts" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>实际收款</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualReceipts | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualExpenditure" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>实际支出</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>费用报销 + 费用付款 + 人工支出 + 税费 + 外协生产费用 + 设备折旧</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualExpenditure | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="fundBalance" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>资金余额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>实际收款 - 实际支出</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.fundBalance | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalRevenue" min-width="130" sortable label="总收入" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalRevenue | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCost" min-width="130" sortable label="总成本" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalProfit" min-width="130" sortable label="总利润" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalProfit | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">月度财务数据</span>
        <span class="title_right"
          ><SearchUserDate
            :width="'100'"
            :type="'monthStatistics'"
            :introductionType="'month'"
            :clearable="true"
            :filterable="true"
            :list="monthGenerate"
            :yearValue="sectionInfo.monthStatisticsYear"
            v-model="sectionInfo.monthStatisticsMonth"
            @change="onMonthStatisticsChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'monthStatistics'"
            :introductionType="'month'"
            :width="'100'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.monthStatisticsMonth"
            v-model="sectionInfo.monthStatisticsYear"
            @change="onMonthStatisticsChange"
        /></span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        > -->
      </div>
      <el-table
        :summary-method="getSummariesMonth"
        show-summary
        max-height="550"
        border
        :data="monthStatisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column prop="salesRevenue" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额（销售合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.salesRevenue | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoicedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应收金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoicedAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="procurement" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>采购金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额带出（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.procurement | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="businessExpend"
          min-width="130"
          sortable
          label="商务报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.businessExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectExpend"
          min-width="130"
          sortable
          label="项目报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.projectExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="publicSpendingExpend"
          min-width="130"
          sortable
          label="公共报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.publicSpendingExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="reimbursements"
          min-width="130"
          sortable
          label="总报销"
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>总报销</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务报销 + 项目报销 + 公共报销</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.reimbursements | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedInvoices" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应付金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>按发起时间算起的所有报销 + 付款申请金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedInvoices | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualExpenditure" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>实际支出</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>费用报销 + 费用付款 + 人工支出 + 税费 + 外协生产费用 + 设备折旧</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualExpenditure | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目统计表.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
      },
      quantityList: [], // 数量统计
      yearStatisticsList: [], // 年度经营数据
      monthStatisticsList: [], // 月度财务数据
      sectionInfo: {
        // 条件筛选
        yearStatisticsYear: null, // 年份
        monthStatisticsYear: null,
        monthStatisticsMonth: null,
        quarter: '',
      },
      titleYear: '统计时间：',
      isDialog: false,
      projectPaymentAmount: 0,
      projectPaymentAmountC: 0,
      projectPaymentAmountMnth: 0,
      projectPaymentAmountCMnth: 0,
    }
  },
  provide() {
    return {}
  },
  computed: {
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.financialBalancesData(),
        this.$api.statistics.financiaAnnualExecutiveData(`date=${val}`),
        this.$api.statistics.financiaMonthlyFinanceReport(`date=${val}`),
      ])
        .then(res => {
          const { financialDataVo = {} } = res[0].data
          const keyMappings = {
            unpaidInvoice: '开票未回款',
            unpaidNationalInvoice: '回款未开票',
            unpaidReceivedInvoice: '收票未付款',
            unreceivedPayment: '付款未收票',
            unreturnedBidDeposit: '未退投标保证金',
            overdueBidDeposit: '超期未退投标保证金',
            unreturnedPerformanceDeposit: '未退履约保证金',
            overduePerformanceDeposit: '超期未退履约保证金',
            unreturnedDeposit: '未退押金',
            overdueDeposit: '超期未退押金',
            unpaidLoan: '未还借款',
          }
          this.quantityList = Object.keys(financialDataVo)
            .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: keyMappings[key], // 根据键获取名称
              value: financialDataVo[key],
            }))

          this.yearStatisticsList =
            res[1].data.annualExecutiveDataView.annualExecutiveDataVoList || []
          this.projectPaymentAmount = res[1].data.annualExecutiveDataView.projectPaymentAmount || 0
          this.projectPaymentAmountC =
            res[1].data.annualExecutiveDataView.projectPaymentAmountC || 0
          this.monthStatisticsList =
            res[2].data.monthlyFinanceDataView.monthlyFinanceDataVoList || []
          this.projectPaymentAmountMnth =
            res[2].data.monthlyFinanceDataView.projectPaymentAmount || 0
          this.projectPaymentAmountCMnth =
            res[2].data.monthlyFinanceDataView.projectPaymentAmountC || 0
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onMonthStatisticsChange(obj) {
      this.monthStatisticsList = obj.monthlyFinanceDataVoList || []
      this.projectPaymentAmountMnth = obj.projectPaymentAmount || 0
      this.projectPaymentAmountCMnth = obj.projectPaymentAmountC || 0
    },
    onYearStatisticsChange(obj) {
      this.yearStatisticsList = obj.annualExecutiveDataVoList || []
      this.projectPaymentAmount = obj.projectPaymentAmount || 0
      this.projectPaymentAmountC = obj.projectPaymentAmountC || 0
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 3) {
              sums[index] = Number(this.projectPaymentAmount.toFixed(2)).toLocaleString()
            } else if (index === 6) {
              sums[index] = Number(this.projectPaymentAmountC.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getSummariesMonth(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 1) {
              sums[index] = Number(this.projectPaymentAmountMnth.toFixed(2)).toLocaleString()
            } else if (index === 4) {
              sums[index] = Number(this.projectPaymentAmountCMnth.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.yearStatisticsYear = dictVal
          this.sectionInfo.monthStatisticsYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.project_individual {
  display: inline-block;
  text-align: center;
  width: 190px;
  font-size: 18px;
  .project_individual_title {
    font-size: 15px;
  }
  .project_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.el-tabs {
  .el-tabs__item {
    color: #000;
  }
}
</style>
